import { Component, ElementRef, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { RoutePath } from '@app/app.routes';
import { NavigationStart, Router } from '@angular/router';
import { StoreService } from '@app/services/store/store.service';
import { ApiService } from '@app/services/api/api.service';
import { GUI_VISIBILITY_MATCH, PERMISSION_TYPE_DENY } from '@app/modules/client/constant';
@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    menu: any[] = [];
    isMenuLoaded = false;
    url: string;
    user: any = {};


    constructor(public layoutService: LayoutService,
        private router: Router,
        private store: StoreService,
        private api: ApiService,
        public el: ElementRef) { 
            this.url = this.router.url;
            router.events.subscribe(event => {
              if (event instanceof NavigationStart) {
                // Navigation started.
                this.url = event.url;
              }
            });
        }

    async ngOnInit() {
      this.menu = [
          {
              label: 'Activities', icon: 'pi pi-home',
              visible: true,
              items: [
                  { label: 'Dashboard', visible: true, icon: 'pi pi-fw pi-home', routerLink: [RoutePath.dashboard] },
                  { label: 'Call Logs', visible: true, icon: 'pi pi-fw pi-phone', routerLink: [RoutePath.callLogs] }
              ]
          },
          {
              label: 'Number Management', visible: true, icon: 'pi pi-fw pi-shopping-cart',
              items: [
                  { label: 'Buy Numbers', visible: true, icon: 'pi pi-fw pi-shopping-cart', routerLink: [RoutePath.numberman.management] },
                  { label: 'Tracking Numbers', visible: true, icon: 'pi pi-fw pi-sync', routerLink: [RoutePath.tracking_number.numbers] },
                  { label: 'Receiving Numbers', visible: true, icon: 'pi pi-fw pi-sliders-h', routerLink: [RoutePath.receiving.numbers] }
              ]
          },
          {
              label: 'Call Source', icon: 'pi pi-fw pi-sync',visible: true,
              items: [
                  { label: 'Tracking Sources', visible: true, icon: 'pi pi-fw pi-sync', routerLink: [RoutePath.tracking_source.sources] }
              ]
          },
          {
              label: 'Routing', visible: true, icon: 'pi pi-fw pi-sliders-v',
              items: [
                  { label: 'Whitelist', visible: true, icon: 'pi pi-fw pi-sliders-v', routerLink: [RoutePath.receiving.whitelist] },
                  { label: 'SIP Gateways', visible: true, icon: 'pi pi-fw pi-sliders-v', routerLink: [RoutePath.receiving.sipgateways] },
                  { label: 'Voice Menus', visible: true, icon: 'pi pi-fw pi-sitemap', routerLink: [RoutePath.ivr] },
                  
              ]
          },
          {
              label: 'Reporting', visible: true, icon: 'pi pi-fw pi-chart-bar',
              items: [
                  { label: 'Activity Reports', visible: true, icon: 'pi pi-fw pi-chart-bar', routerLink: [RoutePath.reports.activity_reports] },
                  { label: 'Overview Reports', visible: true, icon: 'pi pi-fw pi-desktop', routerLink: [RoutePath.reports.overview] },
                  { label: 'System Dialogs', visible: true, icon: 'pi pi-fw pi-arrow-right-arrow-left', routerLink: [RoutePath.system.dialogs] },
                  
              ]
          },
          {
              label: 'Settings', icon: 'pi pi-fw pi-users', visible: true,
              items: [
                  { label: 'Customer Panel', visible: true, icon: 'pi pi-fw pi-users', routerLink: [RoutePath.customer_panel.customer_panel] },
                  { label: 'Account Profile', visible: true, icon: 'pi pi-fw pi-users', routerLink: [RoutePath.user.account] },
                  { label: 'Manage Customers', visible: true, icon: 'pi pi-fw pi-users', routerLink: [RoutePath.customer.customers] },
                  { label: 'Manage Roles', visible: true, icon: 'pi pi-fw pi-user-edit', routerLink: [ RoutePath.role.roles] },
                  { label: 'Manage Users', visible: true,icon: 'pi pi-fw pi-user', routerLink: [RoutePath.user.users] },
                  { label: 'Manage Billings', visible: true, icon: 'pi pi-fw pi-dollar', routerLink: [RoutePath.billing.settings] },
                  { label: 'Manage Products', visible: true, icon: 'pi pi-fw pi-credit-card', routerLink: [RoutePath.product.products] },
                  
              ]
          }
      ];
      await new Promise<void>(resolve => {
          const mainUserInterval = setInterval(() => {
            if (this.store.getUser() && this.store.getGuiVisibility()) {
              clearInterval(mainUserInterval);
    
              resolve();
            }
          }, 100);
        });
    
        this.store.state$.subscribe(async state => {
          if (state.token && state.user && state.guiVisibility) {
            this.user = this.store.getUser();
            this.setMenuHidden(state.guiVisibility);
          }
        });

        this.layoutService.configUpdate$.subscribe((res) => {
          this.store.state$.subscribe(async state => {
              if (state.token && state.user && state.guiVisibility) {
                this.user = this.store.getUser();
                this.setMenuHidden(state.guiVisibility);
              }
            });
        })
    }

    setMenuHidden = guiVisibility => {
        const _user = this.store.getUser();
        const userType = _user.DashRoleMapping[0].principalType;
        const superAdminRestrictedPages = ["Manage Billings", "Account Profile", "Buy Numbers"];
    
        for (const subMenuLvl1 of this.menu) {
          subMenuLvl1.visible = false;
          let bAllLvl2Hidden = true;
    
          for (const subMenuLvl2 of subMenuLvl1.items) {
            subMenuLvl2.visible = false;
            if (subMenuLvl2.label == 'Voice Menus' && userType != "USER") {
              subMenuLvl2.visible = true;
              bAllLvl2Hidden = false;
              continue;
            }
            if (this.store.getUserPermissions()?.isSuperAdmin) {
              subMenuLvl2.visible = true;
              bAllLvl2Hidden = false;
              if (superAdminRestrictedPages.includes(subMenuLvl2.label)) {
                subMenuLvl2.visible = false;
              }
              continue;
            } else if (subMenuLvl2.label == 'Account Profile' || subMenuLvl2.label == 'Manage Billings'  || (subMenuLvl2.label == 'Manage Products' && userType != "USER")) {
              subMenuLvl2.visible = true;
              bAllLvl2Hidden = false;
              continue;
            }
    
            for (const v of guiVisibility) {
              if (GUI_VISIBILITY_MATCH[v.GuiSection.name] == subMenuLvl2.label) {
                if (v.GuiPermission.name != PERMISSION_TYPE_DENY) {
                  subMenuLvl2.visible = true;
                  bAllLvl2Hidden = false;
                }
              }
            }
          }
    
          if (!bAllLvl2Hidden) subMenuLvl1.visible = true;
        }
    
        this.isMenuLoaded = true;
      };
}
